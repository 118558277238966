<template>
  <div class="wait-approval-list">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="approval-item-box" v-for="(item, index) in list" :key="index" @click="handleDetailClick(item)">
        <div class="left">
          <img :src="userIcon" alt="icon" />
        </div>
        <div class="right">
          <h3>{{ item.commitUserName || item.checkUserName || item.receiveUserName }}提交的{{activeKey == 1?'点检':activeKey == 2?'保养':"故障"}}任务</h3>
          <p>审批类型：{{ item.approvalType === 1 ? '会签' : '或签' }}</p>
          <p v-if="activeKey!='2'">提交时间：{{ item.commitTime || item.approvalTime}}</p>
          <p v-else>审批时间：{{ item.finishTime}}</p>
          <span class="status3" v-if="activeKey != 2"> 审批意见：{{ item.approvalDesc }}</span>
          <span class="status3" v-else> 审批人：{{item.realApprovelUserNames }}</span>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import Vue from 'vue'
import { List } from 'vant'
import userIcon from '@/assets/img/my/user-icon.png'
import 'vant/lib/list/style'
import { getMyApprovaledTaskList } from '@/api/approval'

Vue.use(List)
export default {
  name: 'HadApprovalList',
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      userIcon,
      params: {
        page: 0,
        size: 10,
      },
    }
  },
  props: {
    activeKey: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    /**
     * 我的审批列表接口
     */
    getMyApprovaledTaskList() {
      let url =
        this.activeKey == 0
          ? '/mobile/deviceRepairOrder/myApprovaledFaultList'
          : this.activeKey == 1
            ? '/mobile/checkTask/myApprovaledTaskList'
            : '/mobile/deviceRegularMaintenanceOrder/h5/hadApproval/list'
      getMyApprovaledTaskList(url, this.params).then((res) => {
        if (this.params.page === 0) {
          this.list = []
        }
        this.params.page = this.params.page + 1
        if (res.details) {
          res.details.content.forEach((item) => {
            this.list.push(item)
          })
          if (this.list.length >= res.details.totalElements) {
            this.finished = true
          }
        } else {
          res.content.forEach((item) => {
            this.list.push(item)
          })
          if (this.list.length >= res.totalElements) {
            this.finished = true
          }
        }

      })
    },
    handleDetailClick(item) {
      if (this.activeKey == 0) {
        this.$router.push({
          path: '/fault/detail?orderId=' + item.id,
        })
      } else if (this.activeKey == 1) {
        this.$router.push({
          path: '/approval/detail?taskId=' + item.taskId,
        })
      }
      else if (this.activeKey == 2) {
        this.$router.push({
          path: '/maintainInfo/info',
          query: {
            id: item.id,
          },
        })
      }
    },
    onLoad() {
      // 异步更新数据
      this.getMyApprovaledTaskList()
    },
  },
  watch: {
    activeKey(val) {
      console.log(val)
      this.params.page = 0
      this.getMyApprovaledTaskList()
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../../style/mixin';
.wait-approval-list {
  box-sizing: border-box;
  padding: 1rem;
  background-color: #f1f1f1;
  .approval-item-box {
    margin-bottom: 1rem;
    background-color: white;
    border-radius: 0.3rem;
    display: flex;
    box-sizing: border-box;
    padding: 0.7rem;
    .left {
      @include wh(2.3rem, 100%);
      display: flex;
      justify-content: center;
      margin-right: 0.7rem;
      img {
        @include wh(2.3rem, 2.3rem);
      }
    }
    .right {
      width: 0;
      flex: 1;
      line-height: 1.3rem;
      h3 {
        @include sc(0.75rem, #333333);
        font-weight: bold;
      }
      p {
        @include sc(0.7rem, #666666);
      }
      span {
        font-size: 0.7rem;
      }
      span.status1 {
        color: #6cd245;
      }
      span.status2 {
        color: #f56c6c;
      }
      span.status3 {
        color: #4a8cff;
      }
    }
  }
}
</style>
