<template>
  <div class="check-wrapper">
    <div class="tab-wrapper">
      <van-tabs v-model="tabActive">
        <van-tab :title="`待审批`"></van-tab>
        <van-tab :title="`已审批`"></van-tab>
      </van-tabs>
    </div>
    <van-sticky :offset-top="50">
      <div class="tab-sidebar-title">
        <span class="sidebar-title" @click="visible = !visible">分类</span>
        <!-- <van-icon name="more" size="2rem" /> -->
      </div>
      <div class="tab-sidebar">
        <transition name="van-slide-right">
          <van-sidebar v-model="activeKey" v-show="visible">
            <van-sidebar-item title="故障工单" />
            <van-sidebar-item title="巡检工单" />
            <van-sidebar-item title="保养工单" />
          </van-sidebar>
        </transition>
      </div>
    </van-sticky>

    <div class="content-wrapper">
      <WaitApprovalList v-if="tabActive === 0" :activeKey="activeKey" />
      <HadApprovalList v-if="tabActive === 1" :activeKey="activeKey" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Tab, Tabs, Sidebar, SidebarItem } from 'vant'
import WaitApprovalList from './components/WaitApprovalList'
import HadApprovalList from './components/HadApprovalList'
import 'vant/lib/tab/style'
import 'vant/lib/tabs/style'
import 'vant/lib/sidebar/style'
import 'vant/lib/sidebar-item/style'
import 'vant/lib/icon/local.css'
Vue.use(Tab).use(Tabs).use(Sidebar).use(SidebarItem)
export default {
  name: 'Approval',
  components: {
    WaitApprovalList,
    HadApprovalList,
  },
  data() {
    return {
      tabActive: 0,
      activeKey: 0,
      visible: false,
    }
  },
  methods: {
   
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.check-wrapper {
  @include wh(100%, 100%);
  background-color: #f1f1f1;
  box-sizing: border-box;
  padding-top: 44px;
  position: relative;
  .tab-wrapper {
    @include wh(100%, 44px);
    position: fixed;
    left: 0;
    top: 0rem;
    /deep/.van-tab {
      .van-tab__text {
        font-size: 0.85rem;
        color: #333333;
      }
      &.van-tab--active {
        .van-tab__text {
          color: #4a8cff;
        }
      }
    }
    /deep/.van-tabs__line {
      background-color: #4a8cff;
    }
  }
  .tab-sidebar-title {
    position: absolute;
    right: 5px;
    // top: 44px;
    background: linear-gradient(90deg, #4f93ff 0%, #3abbfd 100%);
    font-size: 0.7rem;
    padding: 0.2rem 0.7rem;
    border-radius: 0.7rem;
    .sidebar-title {
      color: #ffffff;
      font-weight: bold;
    }
  }
  .active {
    transform: rotate(90deg);
  }
  .tab-sidebar {
    position: absolute;
    right: 0px;
    top: 1.6rem;
    /deep/.van-icon {
      float: right;
    }
  }
  .content-wrapper {
  }
}
</style>
