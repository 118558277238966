<template>
  <div class="wait-approval-list">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="approval-item-box" v-for="(item, index) in list" :key="index" @click="handleDetailClick(item)">
        <div class="left">
          <img :src="userIcon" alt="icon" />
        </div>
        <div class="right">
          <h3>{{ item.commitUserName || item.actualCheckUserName }}</h3>
          <p>审批类型：{{ item.approvalType === 1 ? '会签' : '或签' }}</p>
          <p>提交时间：{{ item.commitTime || item.checkTime|| item.finishTime }}</p>
          <!-- <span class="status">待审批</span> -->
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import Vue from 'vue'
import { List } from 'vant'
import userIcon from '@/assets/img/my/user-icon.png'
import { getMyTodoApprovalTaskList } from '@/api/approval'

Vue.use(List)
export default {
  name: 'WaitApprovalList',
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      userIcon: userIcon,
      params: {
        page: 0,
        size: 10,
      },
    }
  },
  props: {
    activeKey: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    /**
     * 我的待审批列表接口
     */
    getMyTodoApprovalTaskList() {
      let url =
        this.activeKey == 0
          ? '/mobile/deviceRepairOrder/myTodoApprovalFaultList'
          : this.activeKey == 1
            ? '/mobile/checkTask/myTodoApprovalTaskList' : 'mobile/deviceRegularMaintenanceOrder/h5/unApproval/list'

      getMyTodoApprovalTaskList(url, this.params).then((res) => {
        if (this.params.page === 0) {
          this.list = []
          // this.$emit('handleGetTodoSum', res.totalElements)
        }
        // this.params.page = this.params.page + 1
        if (res.details) {
          res.details.content.forEach((item) => {
            this.list.push(item)
          })
          this.loading = false
          if (this.list.length >= res.details.totalElements || res.details.content.length == '0') {
            this.finished = true
            this.loading = true
          } else {
            this.params.page = this.params.page + 1
          }
        } else {
          res.content.forEach((item) => {
            this.list.push(item)
          })
          this.loading = false
          if (this.list.length >= res.totalElements || res.content.length == '0') {
            this.finished = true
            this.loading = true
          } else {
            this.params.page = this.params.page + 1
          }
        }
      })
    },
    handleDetailClick(item) {
      console.log(this.activeKey)
      if (this.activeKey == 0) {
        this.$router.push({
          path: '/fault/detail?orderId=' + item.id,
        })
      } else if (this.activeKey == 1) {
        this.$router.push({
          path: '/approval/detail?taskId=' + item.taskId,
        })
      }
      else if (this.activeKey == 2) {
        this.$router.push({
          path: '/maintain/detail?orderId=' + item.id,
        })
      }

    },
    onLoad() {
      // 异步更新数据
      setTimeout(() => {
        this.getMyTodoApprovalTaskList()

      }, 500)
    },
  },
  watch: {
    activeKey(val) {
      console.log(val)
      this.params.page = 0

      this.getMyTodoApprovalTaskList()
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../../style/mixin';
.wait-approval-list {
  box-sizing: border-box;
  padding: 1rem;
  background-color: #f1f1f1;
  .approval-item-box {
    margin-bottom: 1rem;
    background-color: white;
    border-radius: 0.3rem;
    display: flex;
    box-sizing: border-box;
    padding: 0.7rem;
    .left {
      @include wh(2.3rem, 100%);
      display: flex;
      justify-content: center;
      margin-right: 0.7rem;
      img {
        @include wh(2.3rem, 2.3rem);
      }
    }
    .right {
      flex: 1;
      line-height: 1.3rem;
      h3 {
        @include sc(0.75rem, #333333);
        font-weight: bold;
      }
      p {
        @include sc(0.7rem, #666666);
      }
      span.status {
        @include sc(0.7rem, #ff9900);
      }
    }
  }
}
</style>
